#playgroundButton {
  position: fixed;
  width: 150rem;
  height: 150rem;
  width: 200rem;
  height: 200rem;
  z-index: 100;
  bottom: -50rem;
  right: -50rem;
  transition: all 0.4s;
  background-color: transparent;
  /* display: none; */
}
#playgroundButton:hover {
  bottom: 0;
  right: 0;
}
#playgroundButton a {
  display: flex;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  /* background-color: #ff351e; */
  text-decoration: none;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  transition: all 0.4s;
  background-image: url(../media/image/playground.svg);
  background-size: 100% 100%;
  animation: playgroundButtonRotate linear 12s infinite;
}
#playgroundButton.playground {
}
#playgroundButton.playground a {
  pointer-events: none;
  background-image: url(../media/image/playground_black.svg);
}
#playgroundButton:hover a {
  /* transform: translate(0%, 0%); */
  animation-duration: 6s;
}

@media screen and (max-width: 767px) {
  #playgroundButton {
    width: 130rem;
    height: 130rem;
  }
}
@keyframes playgroundButtonRotate {
  0% {
    transform: translate(0, 0) rotate(0);
  }
  0% {
    transform: translate(0, 0) rotate(1turn);
  }
}

