.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10rem + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
#mobileCover {
  display: none;
}
#loadingCover {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: #fbfbfb;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
}
#loadingCover img {
  width: 100rem;
  pointer-events: none;
  user-select: none;
}
#loadingCover.active {
  opacity: 1;
  visibility: visible;
}
#loadingCover.hide {
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s;
}
@media screen and (max-width: 767px) {
  
  html {
    font-size: 0.267vw;
  }
  html, body {
    overflow: hidden;
  }
  #menu .logo {
    /* width: 100rem; */
  }
  #menu .menuButton {
    /* visibility: hidden;
    opacity: 0; */
  }
  /* #mobileCover {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #FFFFFF;
    background-image: url(./media/image/Mobile_background.jpg);
    background-position: bottom;
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 99;
  } */
  #mobileCover #mobileLogo {
    width: 60%;
    margin-top: 200rem;
    margin-left: 35rem;
  }
  #mobileCover .hints {
    padding-left: 50rem;
    font-size: 45rem;
    margin: 20rem 0 80rem;
  }
  #mobileCover .contact {
    font-size: 30rem;
    padding-left: 50rem;
    line-height: 1.7em;
  }
  #mobileCover .contact a {
    color: #000;
    text-decoration: none;
  }
}