#footer {
  background-color: #000000;
  color: #FFFFFF;
  height: 650rem;
  position: relative;
  display: flex;
  padding: 0 100rem 100rem;
  align-items: flex-start;
  /* justify-content: space-between; */
  font-size: 20rem;
  
  visibility: visible;
  opacity: 1;
  transition: visibility 0.4s, opacity 0.4s;
}
#footer.hide {
  visibility: hidden;
  opacity: 0;
}
#footer .backgroundCover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  pointer-events: none;
}
#footer .backgroundCover video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#footer .countdown {
  flex: 0 0 33.33333333%;
  position: relative;
  z-index: 1;
  padding: 50rem 0;
  /* padding-left: 50rem; */
  text-align: center;
}
#footer .countdown .timer {
  font-size: 5vw;
  font-family: 'Fixture', sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  margin-top: -0.25em;
}
#footer .contacts {
  /* font-size: 50rem; */
  position: relative;
  z-index: 1;
  flex: 0 0 33.33333333%;
  padding: 50rem 40rem 50rem 50rem;
  line-height: 1.7em;
}
#footer .contacts .name {
  /* font-weight: bold; */
  /* font-family: 'Fixture'; */
  /* font-size: 30rem; */
  /* letter-spacing: .04em; */
 
}
#footer .contacts .email {
  color: #FFFFFF;
  text-decoration: none;
}
#footer .socialMedia {
  padding: 50rem;
  /* flex: 0 0 25%; */
  position: relative;
  z-index: 1;
  text-align: right;
  margin-left: auto;
}
#footer .socialMedia .header {
  /* text-transform: uppercase; */
  /* font-family: 'Fixture'; */
  /* font-size: 30rem; */
  /* letter-spacing: .04em; */
  /* font-weight: 100; */
}
#footer .socialMedia .mediaList {
  margin: 30rem 0;
  display: flex;
  justify-content: space-between;
}
#footer .socialMedia a {
  color: #FFFFFF;
  text-decoration: none;
  margin: 0 15rem;
  display: inline-block;
}
#footer .socialMedia a img {
  width: 50rem;
  fill: #FFFFFF;
}
#footer .socialMedia .joinUs {
  border-top: 1rem solid #FFFFFF;
  padding-top: 15rem;
}

@media screen and (max-width: 767px) {
  #footer {
    flex-direction: column;
    font-size: 20rem;
    padding: 0 15rem 80rem;
    /* align-items: center; */
    height: auto;
  }
  #footer .backgroundCover video {
    opacity: 0.4;
  }
  #footer .contacts {
    order: 2;
    padding: 30rem 0;
  }
  #footer .countdown {
    order: 1;
    width: 100%;
  }
  #footer .socialMedia {
    order: 3;
    padding: 30rem 0;
    width: 100%;
    text-align: left;
  }
  #footer .countdown .timer {
    font-size: 72rem;
  }
  #footer .socialMedia .mediaList {
    justify-content: left;
  }
  #footer .socialMedia a {
    margin: 0 30rem 0 0;
  }
  #footer .socialMedia a img {
    width: 30rem;
  }
}



