@font-face {
  font-family: 'Fixture';
  src: url('./media/fonts/Fixture Ultra SemiBold.otf');
  font-weight: bold;
}
@font-face {
  font-family: 'Mabry Pro';
  src: url('./media/fonts/Mabry Pro Bold.otf');
  font-weight: 900;
}
@font-face {
  font-family: 'Mabry Pro';
  src: url('./media/fonts/Mabry Pro Medium.otf');
  font-weight: 600;
}
@font-face {
  font-family: 'Mabry Pro';
  src: url('./media/fonts/Mabry Pro Italic.otf');
  font-style: italic;
  font-weight: 400;
}
@font-face {
  font-family: 'Mabry Pro';
  src: url('./media/fonts/Mabry Pro Light.otf');
  font-weight: 100;
}
@font-face {
  font-family: 'Mabry Pro';
  src: url('./media/fonts/Mabry Pro.otf');
  font-weight: 400;
}
::selection {
  color: #ff2c00;
  background-color: #3f36b9;
}
html {
  font-size: calc(100vw / 1920);
}
body {
  margin: 0;
  font-family: 'Mabry Pro', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  /* font-size: 0.05208333vw; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html, body, #main {
  height: 100%;
}
#main {
  font-size: 16rem;
}
* {
  box-sizing: border-box;
  cursor: none;
}

.sticker {
  width: 200rem;
  height: 200rem;
  /* overflow: hidden; */
  position: relative;
  transform: rotate(45DEG);
  filter: drop-shadow(10rem 0rem 2rem #777777);
}
.sticker .stickerWrap {
  width: 200rem;
  height: 200rem;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.4s;
}
.sticker:hover .stickerWrap {
  width: 180rem;
  height: 200rem;
  overflow: hidden;
  position: relative;
}
.sticker .stickerFront{
  width: 200rem;
  height: 200rem;
  border-radius: 50%;
  /* background-color: red; */
  background-image: url(./media/image/Sticker.png);
  background-size: contain;
  position: absolute;
  top: 0%;
  left: 0%;
}
.sticker .stickerBack {
  width: 200rem;
  height: 200rem;
  border-radius: 50%;
  background-color: #3528a9;
  position: absolute;
  top: 0%;
  left: 100%;
  transform: translateX(0rem);
  transition: all 0.4s;
}
.sticker:hover .stickerBack {
  transform: translateX(-20rem);
}

.loading-loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  background-color: #fbfbfb;
}
.loading-loading img {
  width: 100rem;
}
@media screen and (max-width: 767px) {
  * {
    cursor: default;
  }
}