#menu {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
}
#menu .logo {
  position: absolute;
  top: 40rem;
  left: 50rem;
  width: 100rem;
}
/* #menu:hover .logo img {
  animation: logoRotate 4s linear infinite;
} */
#menu .menuButton {
  position: absolute;
  top: 50rem;
  right: 50rem;
  width: 80rem;
  height: 80rem;
  /* background-color: #ccc; */
  z-index: 100;
}
#menu .menuButton .line {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 60%;
  height: 5rem;
  background-color: #000;
  transform: translate(-50%, -50%, 0px);
  transition: all 0.4s;
  will-change: transform;
}
#menu .menuButton .line:before {
  content: '';
  display: block;
  position: absolute;
  top: -250%;
  width: 100%;
  height: 100%;
  background-color: #000;
  transform: translateZ(0px) rotate(0);
  transition: top 0.4s 0.4s, transform 0.4s;
  will-change: transform;
}
#menu .menuButton .line:after {
  content: '';
  display: block;
  position: absolute;
  bottom: -250%;
  width: 100%;
  height: 100%;
  background-color: #000;
  transform: translateZ(0px) rotate(0);
  transition: bottom 0.4s 0.4s, transform 0.4s;
  will-change: transform;
}
#menu .menuButton:not(.opened):hover .line:before {
  animation: menuButtonTopJump 0.4s alternate infinite;
}
#menu .menuButton:not(.opened):hover .line:after {
  animation: menuButtonBottomJump 0.4s alternate infinite;
}
#menu .menuButton.opened .line {
  background-color: transparent;
}
#menu .menuButton.opened .line:before {
  top: 0%;
  transform: translateZ(0px) rotate(45DEG);
  transition: top 0.4s, transform 0.4s 0.4s;

}
#menu .menuButton.opened .line:after {
  bottom: 0%;
  transform: translateZ(0px) rotate(-45DEG);
  transition: bottom 0.4s, transform 0.4s 0.4s;

}

#menuCover {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 99;
  /* background-color: pink; */
  /* background-image: url(../media/image/menu_bg.jpg); */
  background-size: cover;
  visibility: hidden;
  opacity: 0;
  transition: all 0.4s;
}
#menuCover.opened {
  visibility: visible;
  opacity: 1;
}
#menuCover .menuBackground {
  background-color: #cacbcd;
  position: absolute;
  overflow: hidden;
  z-index: 0;
}
#menuCover .menuBackground canvas {
  /* filter: invert(1); */
  mix-blend-mode: difference;
  position: relative;
  z-index: -1;
  vertical-align: top;
  /* color: #cad656; */
}
#menuCover .rotatingBrand {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 30%;
  transform: translate(-50%, 50%);
  letter-spacing: 0.027em;
  color: #cacbcd;
  font-family: 'Fixture', sans-serif;
}
#menu .innerLogo {
  position: absolute;
  top: 40rem;
  left: 50rem;
  width: 100rem;
}
#menuCover nav {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 180rem;
  width: 55%;
}
#menuCover nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
#menuCover nav ul li {
  flex: 0 0 40%;
}
#menuCover nav ul li a {
  text-decoration: none;
  color: #000000;
  font-family: 'Fixture', sans-serif;
  position: relative;
  padding-bottom: 0.2em;
  display: block;
  /* margin-left: 140rem; */
  text-transform: uppercase;
  z-index: 1;
  transform: translate(0,0);
  white-space: nowrap;
  letter-spacing: 0.02em;
}
#menuCover nav ul li a.active {
  color: #F00;
}
#menuCover nav ul li a .smallSun2 {
  position: absolute;
  right: 100%;
  top: 0.2em;
  visibility: hidden;
  width: 150rem;
  z-index: -1;
  pointer-events: none;
}
#menuCover nav ul li a:hover .smallSun2,
#menuCover nav ul li a.active .smallSun2 {
  visibility: visible;
}

@keyframes logoRotate {
  0% {
    transform: rotate(0DEG);
  }
  100% {
    transform: rotate(360DEG);
  }
}
@keyframes menuButtonTopJump {
  0% {
    transform: translateZ(0px) translateY(0);
  }
  100% {
    transform: translateZ(0px) translateY(-5rem);
  }
}
@keyframes menuButtonBottomJump {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(5rem);
  }
}

@media screen and (max-width: 767px) {
  #menu .logo {
    top: 20rem;
    left: 20rem;
    width: 50rem;
  }
  #menu .innerLogo {
    /* top: 30rem;
    left: 30rem;
    width: 100rem; */
    top: 20rem;
    left: 20rem;
    width: 50rem;
  }
  
  #menu .menuButton {
    top: 15rem;
    right: 15rem;
  }
  #menu .menuButton .line {
    transform: translate(-50%, -50%);
  }
  #menu .menuButton:not(.opened):hover .line:before {
    animation: none;
  }
  #menu .menuButton:not(.opened):hover .line:after {
    animation: none;
  }
  #menuCover nav {
    font-size: 60rem;
    top : 45%;
    left: 60%;
  }
  #menuCover nav ul {
    flex-direction: column;
  }
  #menuCover nav ul li a .smallSun2 {
    width: 60rem;
  }
  #menuCover .rotatingBrand {
    width: 75%;
  }
}