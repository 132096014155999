.videoPopup {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s;
}
.videoPopup.opened {
  opacity: 1;
  visibility: visible;
}
.videoPopup .videoWrapper {
  position: absolute;
  top: 100rem;
  left: 100rem;
  right: 100rem;
  bottom: 100rem;
}
.videoPopup .videoWrapper iframe {
  width: 100%;
  height: 100%;
}
.videoPopup .closeButton {
  position: absolute;
  right: 25rem;
  top: 25rem;
  width: 75rem;
  height: 75rem;
  cursor: pointer;
}
.videoPopup .closeButton:before,
.videoPopup .closeButton:after {
  content:  '';
  display: block;
  position: absolute;
  top: 50%;
  width: 100%;
  height: 5rem;
  background-color: #FFFFFF;
}
.videoPopup .closeButton:before {
  transform: rotate(45DEG);
}
.videoPopup .closeButton:after {
  transform: rotate(-45DEG);
}

@media screen and (max-width: 767px) {
  .videoPopup .videoWrapper {
    left: 15rem;
    right: 15rem;
  }
}