
.smallSun2 {
  width: 100rem;
  margin: 0 20rem;
  animation: rotate 10s linear infinite;
}
.smallSun2:hover {
  animation-duration: 2s;
}
.smallSun2 .st0{
  fill:none;
  stroke: currentColor;
  stroke-width:2;
  stroke-linecap:round;
  stroke-miterlimit:10;
}
.smallSun2 .st1{
  fill: currentColor;
}
@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(1turn);
  }
}