.defaultCursor {
  border-radius: 50%;
  background-color: #2b2b2b;
  /* border: 1rem solid #ff3601; */
  /* border: 1rem solid #3d2db2; */
  width: 30rem;
  height: 30rem;
  transform: translate(-50%, -50%);
}
@media screen and (max-width: 767px) {
  .customCursor,
  .defaultCursor {
    display: none;
  }
}